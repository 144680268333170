/* $(window).load(function() {
    lp.domEl.$landingPagePreloader.addClass('hidden-preloader');
    $('.grecaptcha-badge').hide();
    setTimeout(lp.initVideoSlider, 500, lp);
}); */

$(document).ready(function() {
    lp.init();
    lp.domEl.$landingPagePreloader.addClass('hidden-preloader');
    $('.grecaptcha-badge').hide();
    setTimeout(lp.initVideoSlider, 500, lp);
});

var lp = window.lp || {};

lp.init = function() {
    lp.observer = lozad();
    lp.observer.observe();
    
    lp.givdomEl();
    lp.initSmoothScrolling();
    lp.initSliders();
    // lp.initMobMenuBtn();
    lp.initEnlargeSlide();
    lp.loginForm();
    lp.scheduleDemo();
    // lp.disclaimerEvent();
    // lp.privacyEvent();

};

lp.givdomEl = function() {
    this.domEl = {};
    this.domEl.$landingPageContainer = $('body');
    this.domEl.$lpHeaderNav = this.domEl.$landingPageContainer.find('.lp-header-nav');
    this.domEl.$formSignTenviz = this.domEl.$landingPageContainer.find('.container-login-form');
    this.domEl.$formSignTenvizHereBtn = this.domEl.$formSignTenviz.find('.form-sign-in-to-tenviz-here-btn');
    this.domEl.$formGetInTouchWithUs = this.domEl.$landingPageContainer.find('.container-bottom-form-panel');
    this.domEl.$formGetInTouchWithUsBtnOpen = this.domEl.$landingPageContainer.find('.form-get-in-touch-with-us-btn-open');
    this.domEl.$containerEnlargingImg = this.domEl.$landingPageContainer.find('.container-enlarging-img');
    this.domEl.$imageEnlargementTtems = this.domEl.$landingPageContainer.find('img.image-enlargement-item');
    this.domEl.$videoEnlargementTtems = this.domEl.$landingPageContainer.find('.video-enlargement-item');
    this.domEl.$blockEnlargingImg = this.domEl.$containerEnlargingImg.find('.block-enlarging-img');
    this.domEl.$lazySliders = this.domEl.$landingPageContainer.find('.lazy');
    this.domEl.$multipleItemsSliders = this.domEl.$landingPageContainer.find('.multiple-items');
    this.domEl.$loginForm = this.domEl.$landingPageContainer.find('#login-form');
    this.domEl.$loginPopup = this.domEl.$landingPageContainer.find('.container-login-form');
    this.domEl.$loginLinkPopup = this.domEl.$landingPageContainer.find('.login-link');
    this.domEl.$loginClosePopup = this.domEl.$loginPopup.find(".close-login");
    this.domEl.$formSignTenvizHereBtn = this.domEl.$landingPageContainer.find('.form-sign-in-to-tenviz-here-btn');
    this.domEl.$singinResult = this.domEl.$landingPageContainer.find('#signin-result');
    // this.domEl.$containerDisclaimer = this.domEl.$landingPageContainer.find('.container-disclaimer');
    // this.domEl.$disclaimerCloseBtn = this.domEl.$containerDisclaimer.find('#disclaimer .close');
    // this.domEl.$disclaimerOpenBtn = this.domEl.$landingPageContainer.find('.disclaimer-open-btn');
    // this.domEl.$containerPrivacy = this.domEl.$landingPageContainer.find('.container-privacy');
    // this.domEl.$privacyCloseBtn = this.domEl.$containerPrivacy.find('#privacy .close');
    // this.domEl.$privacyOpenBtn = this.domEl.$landingPageContainer.find('.privacy-open-btn');
    this.domEl.$landingPagePreloader = this.domEl.$landingPageContainer.find('.landing-page-preloader');
    this.domEl.$requestDemoFormPopup = this.domEl.$landingPageContainer.find('.container-request-demo-form');
    this.domEl.$startFreeTrialBtn = this.domEl.$landingPageContainer.find('.start-free-trial-btn');
    this.domEl.$closeRequestDemoBtn = this.domEl.$requestDemoFormPopup.find(".close-request-demo");
    this.domEl.$signInBtn = this.domEl.$requestDemoFormPopup.find(".sign-in-btn");
    this.domEl.$subscribeForm = this.domEl.$landingPageContainer.find('#subscribe-form');
    this.domEl.$bottomFormPanelForm = this.domEl.$landingPageContainer.find('#bottom-form-panel');
    this.domEl.$requestDemoForm = this.domEl.$landingPageContainer.find('#request-demo');
    
};

lp.eventPreventDefault = function(e) {
    e.preventDefault();
    e.stopPropagation();
};

lp.initSmoothScrolling = function() {
    let $page = $('html, body');

    this.domEl.$lpHeaderNav.find('a[href*="#"]').click(function(e) {
        if (e.target.dataset.class !== 'lp-header-nav-login' &&
            e.target.dataset.class !== 'lp-header-nav-contact-us') {
            $page.animate({
                scrollTop: $(`#${$(e.target).attr("id")}-section`).offset().top -= $('header')[0].offsetHeight
            }, 400);
            return false;
        };
    });
};

lp.initSliders = function() {
    this.domEl.$lazySliders.slick({
        lazyLoad: 'ondemand',
        infinite: true,
        dots: true,
        autoplaySpeed: 3000,
    });
};

// lp.initMobMenuBtn = function(){
//     let self = this;

//     document.onclick = function(e){
//         const mobileMenu = $(".mobile-menu.mobile-menu-active");
//         if(mobileMenu.length && mobileMenu[0].compareDocumentPosition(e.target) != 0){
//            $('.mob-menu-icon-wrapper').click();
//         }
//     }

//     this.domEl.$mobMenuIconWrapper.click(function(e) {
//         e.preventDefault();
//         e.stopPropagation();
//         self.domEl.$mobMenuIconWrapper.find('.menu-icon').toggleClass('menu-icon-active');
//         self.domEl.$mobileMenu.toggleClass('mobile-menu-active');
//         self.domEl.$lpHeaderNav.toggleClass('mobile-menu-open');
//     });
// };

lp.initEnlargeSlide = function() {
    let self = this;

    this.domEl.$imageEnlargementTtems.click(function(e) {
        e.preventDefault();
        e.stopPropagation();
        self.domEl.$blockEnlargingImg.empty();
        self.domEl.$blockEnlargingImg.append(`<img src="${e.target.dataset.src}" alt="img">`);
        self.domEl.$containerEnlargingImg.removeClass('hidden');
    });
    
    this.domEl.$videoEnlargementTtems.click(function(e) {
        e.preventDefault();
        e.stopPropagation();
        self.domEl.$blockEnlargingImg.empty();
        self.domEl.$blockEnlargingImg.append(`<video controls autoplay src="${e.target.dataset.src}" alt="img">`);
        self.domEl.$containerEnlargingImg.removeClass('hidden');
    });

    this.domEl.$blockEnlargingImg.click(function(e) {
        e.preventDefault();
        e.stopPropagation();
        self.domEl.$blockEnlargingImg.empty();
        self.domEl.$containerEnlargingImg.addClass('hidden');
    });
};

lp.loginForm = function() {
    let self = this;

    let subscribeValidator = self.domEl.$subscribeForm.validate({
        rules: {
            inputSubscribeEmail: {
                required: true,
                email: true
            }
        },
        // submitHandler: function(form) {
        //     $.ajax({
        //         type: "POST",
        //         url: "/subscribe",
        //         data: self.domEl.$subscribeForm.serialize(),
        //         success: function(data) {
        //             if (data.status && data.status === "success") {
        //                 let htm = `<label id="email-message" class="message" for="email" style="display: block;">${data.message}</label>`;
        //                 self.domEl.$subscribeForm.find('.form-group-email label').remove();
        //                 self.domEl.$subscribeForm.find('.form-group-email').append(htm);
        //             };
        //         },
        //         error: function(data) {
        //             let htm = `<label id="email-error" class="error" for="email" style="display: block;">${data.responseJSON.email}</label>`;
        //             self.domEl.$subscribeForm.find('.form-group-email label').remove();
        //             self.domEl.$subscribeForm.find('.form-group-email').append(htm);
        //         }
        //     });
        // }
    });

    let bottomFormPanelValidator = self.domEl.$bottomFormPanelForm.validate({
        rules: {
            inputFirstName: {
                required: true,
                firstname: true
            },
            inputLastName: {
                required: true,
                lastname: true
            },
            inputEmail: {
                required: true,
                email: true
            },
        },
        // submitHandler: function(form) {
        //     $.ajax({
        //         type: "POST",
        //         url: "/request-demo",
        //         data: self.domEl.$bottomFormPanelForm.serialize(),
        //         success: function(data) {
        //             let htm = `<label id="submit-message" class="message" for="submit" style="display: block;">${data.message}</label>`;
        //             self.domEl.$bottomFormPanelForm.find('.form-group-submit label').remove();
        //             self.domEl.$bottomFormPanelForm.find('.form-group-submit').append(htm);
        //         },
        //         error: function(data) {
        //             for (let key in data.responseJSON) {
        //                 if (key === 'firstname') {
        //                     let htm = `<label id="firstname-error" class="error" for="firstname" style="display: block;">${data.responseJSON[key][0]}</label>`;
        //                     self.domEl.$bottomFormPanelForm.find('.form-group-firstname label').remove();
        //                     self.domEl.$bottomFormPanelForm.find('.form-group-firstname').append(htm);

        //                 } else if (key === 'lastname') {
        //                     let htm = `<label id="lastname-error" class="error" for="lastname" style="display: block;">${data.responseJSON[key][0]}</label>`;
        //                     self.domEl.$bottomFormPanelForm.find('.form-group-lastname label').remove();
        //                     self.domEl.$bottomFormPanelForm.find('.form-group-lastname').append(htm);

        //                 } else if (key === 'email') {
        //                     let htm = `<label id="email-error" class="error" for="email" style="display: block;">${data.responseJSON[key][0]}</label>`;
        //                     self.domEl.$bottomFormPanelForm.find('.form-group-email label').remove();
        //                     self.domEl.$bottomFormPanelForm.find('.form-group-email').append(htm);
        //                 };
        //             };
        //         }
        //     });
        // }
    });

    let requestDemoValidator = self.domEl.$requestDemoForm.validate({
        rules: {
            inputFirstName: {
                required: true,
                firstname: true
            },
            inputLastName: {
                required: true,
                lastname: true
            },
            inputEmail: {
                required: true,
                email: true
            },
        },
        // submitHandler: function(form) {
        //     $.ajax({
        //         type: "POST",
        //         url: "/request-demo",
        //         data: self.domEl.$requestDemoForm.serialize(),
        //         success: function(data) {
        //             let htm = `<label id="submit-message" class="message" for="submit" style="display: block;">${data.message}</label>`;
        //             self.domEl.$requestDemoForm.find('.form-group-submit label').remove();
        //             self.domEl.$requestDemoForm.find('.form-group-submit').append(htm);
        //         },
        //         error: function(data) {
        //             for (let key in data.responseJSON) {
        //                 if (key === 'firstname') {
        //                     let htm = `<label id="firstname-error" class="error" for="firstname" style="display: block;">${data.responseJSON[key][0]}</label>`;
        //                     self.domEl.$requestDemoForm.find('.form-group-firstname label').remove();
        //                     self.domEl.$requestDemoForm.find('.form-group-firstname').append(htm);

        //                 } else if (key === 'lastname') {
        //                     let htm = `<label id="lastname-error" class="error" for="lastname" style="display: block;">${data.responseJSON[key][0]}</label>`;
        //                     self.domEl.$requestDemoForm.find('.form-group-lastname label').remove();
        //                     self.domEl.$requestDemoForm.find('.form-group-lastname').append(htm);

        //                 } else if (key === 'email') {
        //                     let htm = `<label id="email-error" class="error" for="email" style="display: block;">${data.responseJSON[key][0]}</label>`;
        //                     self.domEl.$requestDemoForm.find('.form-group-email label').remove();
        //                     self.domEl.$requestDemoForm.find('.form-group-email').append(htm);
        //                 };
        //             };
        //         }
        //     });
        // }
    });

    let loginValidator = self.domEl.$loginForm.validate({
        rules: {
            inputEmail: {
                required: true,
                email: true
            },
            inputPassword: {
                required: true
            }
        },
        submitHandler: function(form) {
            self.domEl.$loginForm.find("label.remote-error").remove();
            $.ajax({
                type: "POST",
                url: "/login",
                data: self.domEl.$loginForm.serialize(),
                success: function(data) {
                    self.domEl.$loginForm.find("label.remote-error").remove();

                    if (data.status === "success") {
                        window.location.href = data.content.redirectPath;
                    } else if (data.status === "error" && data.description === "already authorized") {
                        window.location.href = data.content.redirectPath;
                    } else {
                        self.domEl.$loginForm.find('.form-group-pass').append(`
                            <label class='error remote-error'>
                                ${data.description || 'Something went wrong. Please try again.'}
                            </label>
                        `);
                    };
                }
            });
        }
    });

    this.domEl.$loginLinkPopup.off("click").on("click", function(e) {
        self.eventPreventDefault(e);
        self.domEl.$loginPopup.show();
        $('.grecaptcha-badge').show();
        loginValidator.resetForm();
    });

    this.domEl.$formSignTenvizHereBtn.click(function(e) {
        self.eventPreventDefault(e);
    });

    this.domEl.$loginClosePopup.off("click").on("click", function() {
        self.domEl.$loginPopup.hide();
        $('.grecaptcha-badge').hide();
    });

    this.domEl.$formSignTenvizHereBtn.off("click").on("click", function(e) {
        self.eventPreventDefault(e);
        self.domEl.$loginPopup.hide();
        self.domEl.$requestDemoFormPopup.show();
        $('.grecaptcha-badge').show();
    });

   /*  this.domEl.$startFreeTrialBtn.off("click").on("click", function(e) {
        self.eventPreventDefault(e);
        self.domEl.$requestDemoFormPopup.show();
    }); */

    this.domEl.$closeRequestDemoBtn.off("click").on("click", function() {
        self.domEl.$requestDemoFormPopup.hide();
        $('.grecaptcha-badge').hide();
    });

    this.domEl.$signInBtn.off("click").on("click", function(e) {
        self.eventPreventDefault(e);
        self.domEl.$requestDemoFormPopup.hide();
        self.domEl.$loginPopup.show();
        $('.grecaptcha-badge').show();
        loginValidator.resetForm();
    });
};

lp.scheduleDemo = function() {
    let self = this;

    this.domEl.$formGetInTouchWithUsBtnOpen.click(function(e) {
        self.eventPreventDefault(e);
        self.domEl.$formGetInTouchWithUs.show();
        $('.grecaptcha-badge').show();
    });

    this.domEl.$formGetInTouchWithUs.find(".close-login").off("click").on("click", function() {
        self.domEl.$formGetInTouchWithUs.hide();
        $('.grecaptcha-badge').hide();
    });
};

lp.videoSliderParams = {
    lazyLoad: 'ondemand',
    infinite: true,
    dots: true,
    autoplaySpeed: 3000,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    adaptiveHeight: true,
    responsive: [
        {
          breakpoint: 1080,
          settings: {
            slidesToShow: 1
          }
        },
    ]
}

lp.initVideoSlider = function(lp) {
    function getNameBrowser(){
        let ua = navigator.userAgent;
        if (ua.search(/Chrome/) > 0) return 'Google Chrome';
        if (ua.search(/Firefox/) > 0) return 'Firefox';
        if (ua.search(/Opera/) > 0) return 'Opera';
        if (ua.search(/Safari/) > 0) return 'Safari';
        if (ua.search(/MSIE/) > 0) return 'Internet Explorer';
        return '';
    }
    let browser = getNameBrowser();
    let fileExtension = ``;
    if (browser === 'Safari') {
        fileExtension = `jpg`;
    } else {
        fileExtension = `webp`;
    };

    let widthSlide = `26.1vw`;
    let videoPlayerSlider = [{
            "title": "WHAT MOVES TUTORIAL",
            "file": "//tenviz.com/What_Moves_tutorial.mp4#t=0.2",
            'poster': `landing/images/slider-img/video-moment/What_Moves_tutorial_Moment.${fileExtension}`
        },
        // {
        //     "title": "2",
        //     "file": "//tenviz.com/Gold_and_Treasuries.mp4#t=0.1",
        //     'poster': "landing/images/slider-img/video-moment/Gold_and_Treasuries_Moment.webp"
        // },
        {
            "title": "WHERE THE MONEY CAME FROM?",
            "file": "//tenviz.com/10Y_US_vs_Mexican_5Y_Credit.mp4#t=0.1",
            'poster': `landing/images/slider-img/video-moment/10Y_US_vs_Mexican_5Y_Credit_Moment.${fileExtension}`
        },
        {
            "title": "TENVIZ CROSS ASSET APPROACHES",
            "file": "//tenviz.com/FactSet_video_2.7.mp4#t=0.1",
            'poster': `landing/images/slider-img/video-moment/FactSet_video_2.7_Moment.${fileExtension}`
        },
        {
            "title": "WHAT ARE TRANSPORTS STOCKS TELLING US ABOUT THE ECONOMIC CYCLE?",
            "file": "//tenviz.com/Transports_stocks.mp4#t=0.1",
            'poster': `landing/images/slider-img/video-moment/Transports_stocks_Moment.${fileExtension}`
        },
        {
            "title": "CROSS ASSET INSIGHTS ON WHY CHINESE SELLOFF LOOKS TRANSITORY",
            "file": "//tenviz.com/China_SellOff_1.3.mp4#t=0.1",
            'poster': `landing/images/slider-img/video-moment/China_SellOff_1.3_Moment.${fileExtension}`
        },
        {
            "title": "HOW TENVIZ TOOLS NAILED ITALIAN CRISIS",
            "file": "//tenviz.com/Italian_crisis_1.2.mp4#t=0.1",
            'poster': `landing/images/slider-img/video-moment/Italian_crisis_1.2_Moment.${fileExtension}`
        },
        {
            "title": "ARE YOU PROPERLY EQUIPPED TO FIGHT ON THE INVESTMENT FIELD",
            "file": "//tenviz.com/The_Last_samurai_1.5.mp4#t=0.1",
            'poster': `landing/images/slider-img/video-moment/The_Last_samurai_1.5_Moment.${fileExtension}`
        },
        {
            "title": "WHY GETTING FUNDAMENTALS OF A COMPANY RIGHT IS NOT ENOUGH TO PICK THE BEST STOCK",
            "file": "//tenviz.com/with_thumbnail_1.2.mp4#t=0.1",
            'poster': `landing/images/slider-img/video-moment/with_thumbnail_1.2_Moment.${fileExtension}`
        },
        {
            "title": "HOW TO IMPROVE TIMING OF YOUR FUNDAMENTAL IDEAS",
            "file": "//tenviz.com/Timing_1.2.mp4#t=0.1",
            'poster': `landing/images/slider-img/video-moment/Timing_1.2_Moment.${fileExtension}`
        },
        {
            "title": "HOW EQUITY SECTORS SIGNALED MOVES IN RATES",
            "file": "//tenviz.com/_interest_rates_1.5.mp4#t=0.1",
            'poster': `landing/images/slider-img/video-moment/_interest_rates_1.5_Moment.${fileExtension}`
        },
    ];

    for (let i = 0; i < videoPlayerSlider.length; i++) {
        let html = `<div class="image-item-block player">
            <div class="video-container" style="width: 100%;">
                <div class="video-item" id="player${i}" style="width: ${widthSlide}; min-width: 200px;">
                    <video class="lozad" width="100%"  preload="none" controls data-poster="${videoPlayerSlider[i].poster}">
                        <source data-src="${videoPlayerSlider[i].file}" type="video/mp4">
                    </video>
                    <div class="video-item-title"> ${videoPlayerSlider[i].title} </div>
                </div>
            </div>
        </div>`
        $('.iv-content-slider.multiple-items.slider').append(html);
        /* videoPlayerSlider[i].player = new Playerjs({
            id: `player${i}`,
            file: videoPlayerSlider[i].file,
            poster: videoPlayerSlider[i].poster,
        }); */
    };

    lp.domEl.$multipleItemsSliders.slick(lp.videoSliderParams);
    $('.insights-videos-content .sk-circle-bounce').hide();
    lp.observer.observe();
};

lp.disclaimerEvent = function() {
    let self = this;
    this.domEl.$disclaimerCloseBtn.off("click").on("click", function() {
        self.domEl.$containerDisclaimer.hide();
    });

    this.domEl.$disclaimerOpenBtn.off("click").on("click", function(e) {
        self.eventPreventDefault(e);
        self.domEl.$containerDisclaimer.show();
    });
};

lp.privacyEvent = function() {
    let self = this;
    this.domEl.$privacyCloseBtn.off("click").on("click", function() {
        self.domEl.$containerPrivacy.hide();
    });

    this.domEl.$privacyOpenBtn.off("click").on("click", function(e) {
        self.eventPreventDefault(e);
        self.domEl.$containerPrivacy.show();
    });
};