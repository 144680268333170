$(document).ready(function() {
    modals.init();
});

var modals = window.modals || {};

modals.init = function() {
    modals.givdomEl();

    this.domEl.$containerDetails && modals.addEvents(this.domEl.$containerDetails, this.domEl.$detailsCloseBtn, this.domEl.$detailsOpenBtn);
    modals.initMobMenuBtn();
    modals.initPasswordView();
    console.log(1)
};


modals.givdomEl = function() {
    this.domEl = {};
    this.domEl.$container = $('body');
    
    this.domEl.$containerDetails = this.domEl.$container.find('.container-details');
    this.domEl.$detailsCloseBtn = this.domEl.$containerDetails.find('#details .close');
    this.domEl.$detailsOpenBtn = this.domEl.$container.find('.details-open');

    this.domEl.$lpHeaderNav = this.domEl.$container.find('.lp-header-nav');
    this.domEl.$mobMenuIconWrapper = this.domEl.$lpHeaderNav.find('.mob-menu-icon-wrapper');
    this.domEl.$mobileMenu = this.domEl.$lpHeaderNav.find('.mobile-menu');

    this.domEl.$inputPassword = this.domEl.$container.find('#inputPassword');
    this.domEl.$togglePassword = this.domEl.$container.find('#togglePassword');
};

modals.eventPreventDefault = function(e) {
    e.preventDefault();
    e.stopPropagation();
};

modals.addEvents = function($container, $closeBtn, $openBtn) {
    let self = this;
    $closeBtn.off("click").on("click", function() {
        self.close($container);
        
    });
    $openBtn.off("click").on("click", function(e) {
        console.log()
        self.eventPreventDefault(e);
        self.open($container);
    });
};

modals.open = function(el){
    el.attr("style", "display:block!important");
    el.children().attr("style", "display:block!important");
    el.children().toggleClass("fade");

}

modals.close = function(el){
    el.attr("style", "display:none!important");
    el.children().attr("style", "display:none!important");
    el.children().toggleClass("fade");
}

modals.initMobMenuBtn = function(){
    let self = this;

    document.onclick = function(e){
        const mobileMenu = $(".mobile-menu.mobile-menu-active");
        if(mobileMenu.length && mobileMenu[0].compareDocumentPosition(e.target) != 0){
           $('.mob-menu-icon-wrapper').click();
        }
    }

    this.domEl.$mobMenuIconWrapper.click(function(e) {
        e.preventDefault();
        e.stopPropagation();
        self.domEl.$mobMenuIconWrapper.find('.menu-icon').toggleClass('menu-icon-active');
        self.domEl.$mobileMenu.toggleClass('mobile-menu-active');
        self.domEl.$lpHeaderNav.toggleClass('mobile-menu-open');
    });
};

modals.initPasswordView = function() {
    const self = this

    this.domEl.$togglePassword.click(function() {
        self.domEl.$togglePassword.toggleClass("fa-eye fa-eye-slash")
        self.domEl.$inputPassword.attr("type") == "password" ? self.domEl.$inputPassword.attr("type", "text") : self.domEl.$inputPassword.attr("type", "password");
    })
}